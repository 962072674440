<template>
	<div>
		<InPageImage></InPageImage>
		<div class="wrapper bg-white" style="padding:0 30px;box-sizing: border-box;">
			<Breadcrumb :crumbsList="pathList"></Breadcrumb>
			<div class="base-font-40 text-center padding-tb-20 margin-top-20">{{wzContent.title}}</div>
			<div class="newsLable flex align-center justify-center text-888 margin-bottom-30">
				<span>文章出处：{{wzContent.source}}</span>
				<span>阅读量：{{wzContent.perusal?wzContent.perusal:0}}</span>
				<span>发表时间：{{wzContent.createTimeStr}}</span>
			</div>

			<div class="newsAbstract text-left text-666  margin-bottom-20">
				<el-row :gutter="20">
					<el-col :span="16">
						<div class="preoImage"><el-image :src="wzContent.image" fit="contain"></el-image></div>
					</el-col>
					<el-col :span="8">
						<div style="height: 400px;width: 100%;" class="flex align-center justify-center">
							<div class="text-center">
								<div class="buyProBtn" @click="centerDialogVisible=true">
									<div><i class="el-icon-takeaway-box base-font-48"></i></div>
									<div>索要样品</div>
								</div>
								<div class="buyProBtn">
									<div><i class="el-icon-shopping-cart-2 base-font-48"></i></div>
									<div>在线订购</div>
								</div>
							</div>
						</div>

					</el-col>

				</el-row>


			</div>
			<div style="padding:20px 0;">
				 <el-divider><span class="base-font-20 text-bold">产品描述</span></el-divider>
			</div>
			
			<div v-html="wzContent.content" class="newsContent text-left">
			</div>
			<el-dialog title="索要样品" :visible.sync="centerDialogVisible" width="500px" center>
				<el-form ref="form" :model="form" label-width="90px">
					<el-form-item label="产品名称">
						<el-input v-model="form.cpmc"></el-input>
					</el-form-item>
					<el-form-item label="联系人姓名">
						<el-input v-model="form.name" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="电话">
						<el-input v-model="form.tel" placeholder="请输入联系电话"></el-input>
					</el-form-item>
					<el-form-item label="地址">
						 <el-input type="textarea" v-model="form.address" placeholder="请输入地址"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="centerDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="centerDialogVisible = false">提交</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '../../components/breadcrumbs.vue'
	import InPageImage from '../../components/inPageImage.vue'
	import {
		getWzContentDetail,
		menuListById
	} from "@/api/api";
	export default {
		components: {
			Breadcrumb,
			InPageImage
		},
		data() {
			return {
				menuId: '',
				id: '',
				pathList: [],
				curMenu: {},
				wzContent: {},
				centerDialogVisible: false,
				form:{
					cpmc:''
				}
			}
		},
		mounted() {
			this.menuId = this.$route.query.menuId;
			this.id = this.$route.query.id;
			this.menuListById();
			this.getWzContentDetail();
		},
		methods: {
			menuListById() {
				menuListById(this.menuId).then(res => {
					this.pathList = res.data.pathList;
					this.pathList.push({
						name: res.data.curMenu.name + '详情',
						path: ''
					});
					this.curMenu = res.data.curMenu;
				})
			},
			getWzContentDetail() {
				getWzContentDetail(this.id).then(res => {
					this.wzContent = res.data;
					this.wzContent.createTimeStr = this.$moment(res.data.clickTime).format('YYYY-MM-DD');
				})
			},
		}
	}
</script>

<style>
	.buyProBtn {
		padding: 50px 0;
		cursor: pointer;
	}

	.buyProBtn:hover {
		color: #253783;
		font-weight: bold;
	}

	.buyProBtn+.buyProBtn {
		border-top: 1px solid #dddddd
	}

	.preoImage {
		height: 400px;
	}

	.preoImage .el-image {
		width: 100%;
		height: 400px;
	}

	.newsLable span {
		padding: 0 20px;
	}

	.newsLable span+span {
		border-left: 1px solid #dddddd;
		line-height: 1
	}

	.newsAbstract {
		background: #f0f5f8;
		padding: 50px 60px;
		box-sizing: border-box;
		line-height: 2.1;
	}

	.newsContent {
		line-height: 2.1;
		padding: 0 0 50px 0;
	}
</style>