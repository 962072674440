<template>
	<div>
		<div class="padding-tb-20" style="border-bottom:1px solid #f1f1f1;">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item v-if="crumbsList.length==1" :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item  v-for="(item,index) in crumbsList" :key="index" >
          <a v-if="index>0" :href="item.path">{{item.name}}</a>
          <a v-else>{{item.name}}</a>
        </el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
	
	export default{
		props:{
			crumbsList:Array
		},

		data(){
			return{
        list:[]
				// crumbsList:[
				// 	{id:1,name:"首页",url:"/"},
				// 	{id:2,name:"关于我们",url:"/"},
				// 	{id:3,name:"简历投递",url:"/"}
				// ]
				
			}
		},
		methods:{
			goUrl(url){
				console.log(url,'url22222')
			}
		}
	}
</script>

<style>
</style>